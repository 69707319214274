import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-scroll';

const buttonLine1 = ["Spectacle", "Sunglasses", "Contact Lenses", "Prescription Lenses", ]
const buttonLine2 = [ "Sunglasses", "Contact Lenses", "Prescription Lenses", "Spectacle",]
const buttonLine3 = [ "Contact Lenses", "Prescription Lenses", "Spectacle", "Sunglasses",]
const allBrands = [
    {
        title: 'Mix',
        images: [
            `${require('./brand/sunglass 1.jpg')}`, 
            `${require('./brand/spectacles 1.jpg')}`,
            `${require('./brand/sunglass-5.jpg')}`,
            `${require('./brand/sunglass 2.jpg')}`,
            `${require('./brand/spectacleprescription lens.jpg')}`,
            `${require('./brand/spectacles 4.jpg')}`,
        ]
    },
    {
        title: 'Spectacle',
        images: [
            `${require('./brand/spectacles 1.jpg')}`,
            `${require('./brand/spectacles 2.jpg')}`,
            `${require('./brand/spectacles 3.jpg')}`,
            `${require('./brand/spectacles 4.jpg')}`,
            `${require('./brand/spectacles.jpg')}`,
            `${require('./brand/spectacles 5.jpg')}`,
        ]
    },
    {
        title: 'Sunglasses',
        images: [
            `${require('./brand/sunglass 1.jpg')}`, 
            `${require('./brand/sunglass 2.jpg')}`, 
            `${require('./brand/sunglass 6.jpg')}`, 
            `${require('./brand/sunglass 7.jpg')}`, 
            `${require('./brand/sunglass-4.jpg')}`, 
            `${require('./brand/sunglass-5.jpg')}`, 

          
        ]
    },
    {
        title: 'Contact Lenses',
        images: [
            `${require('./brand/contact lens.jpg')}`,
            `${require('./images/sunGlass3.jpg')}`,
            `${require('./brand/contactLance.jpg')}`,  
        ]
    },
    {
        title: 'Prescription Lenses',
        images: [
            `${require('./brand/spectacleprescription lens.jpg')}`,
            `${require('./brand/sp1.jpg')}`,
            `${require('./brand/sp2.jpg')}`,
        ]
    },
     

]

const OurProduct = () => {

    const [scrollPosition, setScrollPosition] = useState(0);
    const [brands, setBrands] = useState('Mix')
    const [filterBrands, setFilterBrands] = useState()
    useEffect(() => {
        AOS.init({ duration: 1500 });
    })

    useEffect(() => {
        const div = document.querySelector(".ourProduct");
        div.addEventListener("mousemove", parallax);
        function parallax(e) {
            document.querySelectorAll(".chashma").forEach((el) => {
                var moving_value = el.getAttribute("data-value");
                var x = (e.clientX * moving_value) / 200;
                var y = (e.clientY * moving_value) / 200;
                el.style.transform = "translateX(" + x + "px) translateY(" + y + "px)";
            });
        }
    });


    useEffect(() => {
        setFilterBrands(allBrands?.filter((el) => el?.title == brands))
    }, [brands, allBrands])
    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div style={{overflowX:'hidden'}}>
            <Helmet>
                <meta charset="utf-8" />
                <title>Our Products | Premium Eyeglasses at Chasmaghar</title>
                <meta name="description" content="Explore our wide range of premium eyeglasses at Chasmaghar. From 
designer frames to prescription glasses, find the perfect eyewear to 
suit your style and needs." />
                <meta name="keywords" content="Chasmaghar products, premium eyeglasses, designer frames, prescription 
glasses, luxury eyewear, high-quality eyeglasses, eyewear in 
anan,eyewear in nadiad, eyewear in vvn,  " />
            </Helmet>
            <div style={{ height: '100vh' }} class='position-relative d-flex align-items-center justify-content-center ourProduct'>
                <div class='chashmaMain' >
                    <div class='chashma' data-value={8}>
                        <img src={require('./images/ourProduct.png')} style={{ width: '100%' }} alt="" />
                    </div>
                </div>
                <div class='col-xl-4  col-md-6 col-11 m-auto text-start'>
                    <p class='display-1 playfair' data-aos="fade-up">OUR</p>
                    <p class='display-1 playfair' data-aos="fade-up">PRODUCT</p>
                    <p class='fs-5' data-aos="fade-up">Chashmaghar offers a comprehensive selection of eyeglasses, sunglasses, and contact lenses to cater to every vision need and style preference</p>
                    <Link
                        activeClass="active"
                        to='products'
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >

                        <button class="button mt-4" data-aos="fade-up"> <span class='d-flex gap-4'> <span>Explore Products</span> <span><i class="bi bi-chevron-right"></i></span></span></button>
                    </Link>
                </div>
            </div>


            <div style={{ background: '#E7F8FF' }}>
                <div>
                    <p class='ourBrandText' style={{ color: '#fff' }} data-aos="fade-down">CATEGORIES</p>
                </div>
                <div class='col-11 m-auto products'>
                    <div class='col-md-4 col-sm-6 col-11 text-start'>
                        <p class='display-5 fw-semibold' data-aos="fade-up">Your One-Stop Shop for Top <span class='playfair  fw-normal'>Brands</span> </p>
                    </div>
                </div>
                <div style={{ overflow: 'hidden' }}>

                    <div class='d-flex gap-5  mt-4' style={{ transform: `translateX(-${scrollPosition * 0.9}px)` }}>
                        <div class='col-md-10 col-3 '></div>
                        {
                            buttonLine1?.map((el) => {
                                return (

                                    <div class='catagoryButton border' onClick={() => setBrands(el)}>
                                        <p>
                                            {el}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div class='d-flex gap-5  mt-4' style={{ transform: `translateX(-${scrollPosition * 0.99}px)` }}>
                        <div class='col-md-11 col-4'></div>

                        {
                            buttonLine2?.map((el) => {
                                return (

                                    <div class='catagoryButton border' onClick={() => setBrands(el)}>
                                        <p>
                                            {el}
                                        </p>
                                    </div>
                                )
                            })
                        }

                    </div>
                    <div class='d-flex gap-5  mt-4' style={{ transform: `translateX(-${scrollPosition * 1.1}px)` }}>
                        <div class='col-md-12 col-5'></div>
                        {
                            buttonLine3?.map((el) => {
                                return (

                                    <div class='catagoryButton border' onClick={() => setBrands(el)}>
                                        <p>
                                            {el}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <br />
                </div>
            </div>

            <div class=' ' style={{ backgroundImage: `url(${require('./images/hbBg.jpg')})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}>
                <br />
                <div class='row  col-11 m-auto my-4 h-100'>
                    {
                       filterBrands?.[0]?.images?.map((el,i) => {
                            return (
                                <div class='col-lg-4 col-md-6 my-2 ' data-aos="zoom-in-up">
                                    <div class='col-12 m-auto ourBranchImg h-100'> 
                                        <img src={el}   alt="" />
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
                <br />
            </div>
        </div>
    )
}

export default OurProduct