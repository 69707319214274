import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';


const awards = [
    {
        year: "1954",
        title: "<p> <sapn>Chashmaghar</sapn>  <span class='playfair'> ANAND </span></p>",
        text: "Chashmaghar’s flagship store at MG Road, Anand was started in 1954",
        image: `${require('./images/cg1954.jpg')}`
    },
    {
        year: "2005",
        title: "<p> <sapn>Chashmaghar</sapn>  <span class='playfair'> VVN </span></p>",
        text: "Started in 2005 to serve the customers of Vallabh Vidya Nagar",
        image: `${require('./images/cg2004.jpg')}`
    },
    {
        year: "2022",
        title: "<p> <sapn>Chashmaghar</sapn>  <span class='playfair'> LUXE </span></p>",
        text: "In 2022, we launched Chashmaghar Luxe, a luxury eyewear boutique offering over 40+ premium luxury brands.",
        image: `${require('./images/cg2022.jpg')}`
    },
    {
        year: "2024",
        title: "<p> <sapn>Chashmaghar</sapn>  <span class='playfair'> NADIAD </span></p>",
        text: "In 2024, we launched a full-fledged optical and contact lens clinic to cater to the needs of our valued customers in Nadiad",
        image: `${require('./images/cg2024.jpg')}`
    },


]

const AboutUs = () => {

    const [awardFilter, setAwardFilter] = useState(awards?.[0]?.year)
    const [award, setAward] = useState()
    useEffect(() => {
        setAward(awards?.filter((el) => el?.year == awardFilter))
    }, [awardFilter])




    useEffect(() => {
        AOS.init({ duration: 1500 });
    })
    return (
        <div style={{ overflow: 'hidden' }}>
            <Helmet>
                <meta charset="utf-8" />
                <title>About Chasmaghar | Your Local Premium Eyeglasses Showroom</title>
                <meta name="description" content="Learn about Chasmaghar, your trusted source for premium eyeglasses. With
 local showrooms in various cities, we offer personalized service and 
high-quality eyewear." />
                <meta name="keywords" content="About Chasmaghar, premium eyeglasses, local eyewear showrooms, 
high-quality eyewear, designer glasses, eyeglasses, luxury eyeglasses
Our Products Page" />
            </Helmet>
            <div style={{ height: '100px' }}></div>

            <div class='row aboutUs align-items-center'>
                <div class='col-md-5'>
                    <img src={require('./images/aboutTop.jpg')} data-aos="fade-right" style={{ width: '100%' }} alt="" />
                </div>
                <div class='col-md-7 position-relative'>
                    <div class=' position-absolute' style={{ width: '200px', aspectRatio: '1/1', transform: 'rotate(-20deg)', top: '-10%', right: '-20px' }}>
                        <img data-aos="fade-down-left" src={require('./images/topSlider3.jpg')} style={{ width: '100%', height: '100%', aspectRatio: '1/1', borderRadius: '10px' }} alt="" />
                    </div>
                    <div class='col-11 m-auto text-start'>
                        <p class='display-1 playfair' data-aos="fade-up">Chashmaghar </p>
                        <p class='fs-1 playfair' data-aos="fade-up"> A Legacy of Vision Care
                        </p>

                        <p class='f-5 mt-2 tJustify' data-aos="fade-up">Founded in 1954 by Shri Dalsukhlal Desai, Chashmaghar has been a cornerstone of eye care in the Charotar region of Gujarat for over seven decades. What began as a small optical outlet on Anand's Mahatma Gandhi Road has blossomed into a network of renowned optical showrooms and contact lens clinics.</p>
                        <p class='f-5 mt-2 tJustify' data-aos="fade-up">Under the visionary leadership of Mr. Naishad Desai, who joined the family business in 1984, Chashmaghar has consistently elevated the standards of eye care in the region. Mr. Naishad was instrumental in the transformation of Chashmaghar from a single outlet optical store to a multi-outlet optical chain</p>

                        <button class="button mt-4" data-aos="fade-up"> <span class='d-flex gap-4'> <span>Explore Products</span> <span><i class="bi bi-chevron-right"></i></span></span></button>
                    </div>

                </div>

            </div>
            <div>
                <div>
                    <p class='ourBrandText' data-aos="fade-down">ESTABLISH ON</p>
                </div>
                <div class=' col-11 m-auto row gap-md-0 gap-4  align-items-center justify-content-center my-4' style={{ backgroundImage: `url(${require('./images/1999.png')})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}>
                    <div class='col-md-6' >
                        <div class='col-10 m-auto'>
                            <img data-aos="zoom-in" src={require('./images/storeOwner2.png')} style={{ width: '100%', height: '400px', objectFit: 'contain' }} alt="" />
                        </div>
                        <div class='col-11 m-auto text-start mt-2'>
                            <p class='fs-1 playfair' data-aos="fade-up">Mr. Dalsukhlal Desai </p>
                            <p class='f-5 tJustify' data-aos="fade-up">Founder</p>
                        </div>
                    </div>
                    <div class='col-md-6' >
                        <div class='col-10 m-auto'>
                            <img data-aos="zoom-in" src={require('./images/storeOwner.jpg')} style={{ width: '100%', height: '400px', objectFit: 'contain', }} alt="" />
                        </div>
                        <div class='col-11 m-auto text-start mt-2'>
                            <p class='fs-1 playfair' data-aos="fade-up">Mr. Naishad Desai </p>
                            <p class='f-5 tJustify' data-aos="fade-up">CEO</p>
                        </div>
                    </div>

                </div>
                {/* <div class='col-11 m-auto text-start my-4'>
                    <div class='col-lg-6 col-md-9'>
                        <p class='fs-1 playfair' data-aos="fade-up">Naishedbhai Patel</p>
                        <p class='f-5 tJustify' data-aos="fade-up">In 1999, Chsmaghar was born out of a vision to redefine the eyewear experience. With a commitment to quality and customer satisfaction, our journey began in a small storefront, driven by passion and a desire to make a difference in the optical industry.</p>
                    </div>

                </div> */}
            </div>
            <div class='position-relative' style={{ backgroundImage: `url(${require('./images/hbBg.jpg')})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}>
                <div class='position-absolute' style={{ top: '0', left: '0', width: '100%' }}>
                    {/* <p class='ourBrandText' data-aos="fade-down">WHY</p> */}
                </div>
                <br /> <br /> <br />

                <div class='row text-start align-items-end text-white '>

                    <div class='col-lg-6 my-4'>
                        <div class='col-11 m-auto'>
                            <p class='display-2 playfair' data-aos="fade-up">WHY </p>
                            <p class='display-2 playfair' data-aos="fade-up">CHASHMAGHAR? </p>
                            <p class='fs-5 tJustify' data-aos="fade-up">Chashmaghar is proud to be a part of multiple expert programs by globally recognized eyewear and lens brands. This collaboration allows us to stay at the forefront of industry advancements and offer our customers the latest in eye care technology.</p>
                            <p class='fs-5 tJustify' data-aos="fade-up">At the heart of Chashmaghar is our unwavering commitment to customer satisfaction. Armed with state-of-the-art equipment and a team of highly skilled professionals, we strive to provide exceptional eye care solutions tailored to individual needs. </p>
                            <p class='fs-5 tJustify' data-aos="fade-up">Trust Chashmaghar for all your eyewear requirements. Experience the difference of unparalleled service and expertise </p>

                        </div>
                    </div>
                    <div class='col-lg-6 my-4'>
                        <img data-aos="fade-up" src={require('./images/about3.jpg')} style={{ width: '100%' }} alt="" />
                    </div>
                </div>
                <br /> <br />
            </div>


            <div style={{ background: '#D8F5FF' }}>
                <div class='position-relative'>
                    <div >

                        <p class='ourBrandText' style={{ color: 'white' }} data-aos="fade-down">OUR JOURNEY</p>

                    </div>
                    <div class='position-absolute d-flex align-items-center justify-content-center' style={{ width: '100%', left: '0', top: '0', height: '100%' }}>
                        <p class='fs-1 playfair' data-aos="fade-up">OUR JOURNEY</p>
                    </div>
                </div>

                <div class='row align-items-center'>
                    <div class='col-sm-11 col-9 p-0'>
                        <div class='col-11 m-auto row align-items-center p-0'>
                            <div class='col-md-6 p-0'>
                                <img data-aos="fade-right" src={award?.[0]?.image} alt="" style={{ width: '100%' }} />
                            </div>
                            <div class='col-md-6 p-0'>
                                <div class='col-11 m-auto text-start'>
                                    <div style={{ width: 'fit-content' }}>
                                        <p class='display-1 playfair' data-aos="fade-up"  >{award?.[0]?.year} </p>
                                        <hr data-aos="fade-up" />
                                    </div>
                                    <p class='fs-1 playfair' data-aos="fade-up" dangerouslySetInnerHTML={{ __html: award?.[0]?.title }}></p>
                                    <p class='tJustify' data-aos="fade-up">{award?.[0]?.text} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='col-sm-1 col-3 p-0'>
                        <div>
                            {awards?.map((el) => {
                                return (
                                    <p class='fs-2 playfair fw-bold' data-aos="fade-left" onClick={() => setAwardFilter(el?.year)} style={el?.year == awardFilter ? { color: 'rgba(0,0,0)', cursor: 'pointer' } : { color: 'rgba(0,0,0,0.2)', cursor: 'pointer' }}>{el?.year}</p>
                                )
                            })}
                        </div>
                    </div>


                </div>

            </div>
        </div>
    )
}

export default AboutUs